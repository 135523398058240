import { template as template_8210c3496775497bbacdc37e8f550ad9 } from "@ember/template-compiler";
const FKText = template_8210c3496775497bbacdc37e8f550ad9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
